.background-video {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.video {
  width: 100%;
  height: auto;
  display: block;
}

.content-container {
  position: relative;
  z-index: 1;
}

.component {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.component.active {
  opacity: 1;
}
