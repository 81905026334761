.bounce {
  animation-name: bounce;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.bounce1 {
  animation-name: bounce1;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}

@keyframes bounce1 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce2 {
  animation-name: bounce2;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}

@keyframes bounce2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #dbd9e2 0%,
    #f4f4f5 29%,
    #5b9ddb 67%,
    #17e2e9 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite, bounce4 4s infinite;
  display: inline-block;
  font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes bounce4 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
