* {
  margin: 0;
  padding: 0;
}

html {
  font-family: Arial, Helvetica, sans-serif;
}

.section-3 {
  background-color: #8f22d8;
  color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
